import * as React from "react";
import "antd/dist/antd.css";
import "../assets/styles.scss";
import Main from "../layout/layout";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SEO from "../layout/seo";
// markup
const About = ({ location }) => {
  const { t } = useTranslation();

  return (
    <main>
      <SEO title={t("About")} description={t("Description about")}></SEO>
      <Main location={location}>
        <div className="main-container">
        
        <div className="404">
          404 - NOT FOUND
        </div>
        </div>
      </Main>
    </main>
  );
};
export const queryAbout = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default About;
